
import { reactive } from "vue"
import TabsComponent from "@/components/navigation/TabsComponent.vue"

export default {
  components: { TabsComponent },
  props: {
    organization: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const tabItems = reactive([
      { label: "Processing presets", routeName: "organizationProcessing" },
      { label: "CP Presets", routeName: "organizationCustomProcessings" },
      { label: "Orientation", routeName: "organizationOrientation" },
      { label: "Cropping", routeName: "organizationCropping" },
      { label: "Annotations", routeName: "organizationAnnotations" },
      { label: "Context Vision", routeName: "organizationCvinput" },
      { label: "Windowings", routeName: "organizationWindowings" },
      { label: "Custom Processings", routeName: "organizationRiCustomProcessings" },
      { label: "Lut options", routeName: "organizationLuts" },
      { label: "Luts", routeName: "organizationRiluts" },
    ])

    return {
      tabItems,
    }
  },
}
