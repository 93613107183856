import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7966061b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-body imaging-tab-body" }
const _hoisted_2 = { class: "sub-tab-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsComponent = _resolveComponent("TabsComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabsComponent, {
      items: $setup.tabItems,
      baseline: true
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, { organization: $props.organization }, null, 8, ["organization"])
    ])
  ]))
}